import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRefs, nextTick, onMounted } from 'vue';
import { PlusOutlined, CloseOutlined, StarFilled } from '@ant-design/icons-vue';
import { getPosts, addPosts, setPosts, deletePosts } from '@/api/role';
export default defineComponent({
  components: {
    PlusOutlined,
    CloseOutlined,
    StarFilled
  },

  setup(props, context) {
    onMounted(() => {
      getPostsData();
    });
    const inputRef = ref();
    const state = reactive({
      tags: [],
      inputVisible: false,
      inputValue: ''
    });

    const handleClose = id => {
      deleteLabel(id);
    };

    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };

    const handleInputConfirm = () => {
      const inputValue = state.inputValue.replace(/\s/g, "");
      let tags = state.tags;
      if (inputValue) addLabel(inputValue);
      Object.assign(state, {
        tags,
        inputVisible: false,
        inputValue: ''
      });
    }; //获取标签


    const getPostsData = async () => {
      let res = await getPosts({
        jobType: 0
      }); // console.log('111111',res)

      if (res.code === 200) {
        state.tags = [];
        res.data.jobList.forEach(item => {
          state.tags.push({
            id: item.jobNo,
            label: item.jobName,
            visible: true
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //新增标签


    const addLabel = async labelName => {
      let res = await addPosts({
        jobType: 0,
        jobName: labelName
      });

      if (res.code === 200) {
        _message.success('添加成功');

        getPostsData();
      } else {
        _message.error(res.message);
      }
    }; //删除标签


    const deleteLabel = async id => {
      let res = await deletePosts({
        jobNo: id
      });

      if (res.code === 200) {
        _message.success('删除成功');

        getPostsData();
      } else {
        _message.error(res.message);
      }
    };

    const handleChange = val => {
      state.tags.forEach(item => {
        if (item.id === val) {
          item.visible = !item.visible; // console.log(item)
        }
      }); // console.log(val)
    };

    return { ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      inputRef,
      handleChange
    };
  }

});