import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { QuestionCircleOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
import jurisdictionSetModal from './components/jurisdictionSetModal.vue';
import labelManagement from './components/labelManagement.vue';
import { getPosts, addPosts, setPosts, deletePosts, queryEmployee, jobQuery, jobModify } from '@/api/role';
import { getMenuTree, treeForeach, treeFilter } from '@/utils/setMenuTree';
import { useStore } from 'vuex';
import Cookies from "js-cookie"; //表头

const columns = [{
  title: '岗位名称',
  dataIndex: 'jobName',
  slots: {
    customRender: 'jobName'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const peopleColumns = [{
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '电话',
  dataIndex: 'iPhone'
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    EditOutlined,
    CheckOutlined,
    jurisdictionSetModal,
    labelManagement
  },

  setup() {
    //新增岗位
    const addPostVisible = ref(false);

    const addPost = () => {
      addPostVisible.value = true;
    };

    const formState = reactive({
      jobName: ''
    });
    const formRef = ref();

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        // console.log('values', formState);
        const param = {
          jobType: 1,
          jobName: formState.jobName
        };
        let res = await addPosts(param);

        if (res.code == 200) {
          _message.success('添加成功！');

          addPostVisible.value = false;
          getTableData();
        } else {
          _message.error('添加失败！');

          addPostVisible.value = true;
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleCancel = () => {
      addPostVisible.value = false;
      formRef.value.resetFields();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); // //分页信息
    // const {
    //   paginationConfig, total, current, pageSize
    // } = useTablePagination()
    // //点击分页
    // const onPageChange = (pagination) => {
    //   current.value = pagination.current
    //   // getTableData()
    // }
    //表格行编辑

    const editableData = reactive({});

    const edit = key => {
      editableData[key] = cloneDeep(tableData.value.filter(item => key === item.key)[0]);
    };

    const save = async key => {
      Object.assign(tableData.value.filter(item => key === item.key)[0], editableData[key]);

      if (editableData[key].jobName === "") {
        _message.error('岗位名称不能为空！');

        return;
      }

      let res = await setPosts({
        jobNo: key,
        jobName: editableData[key].jobName
      });

      if (res.code === 200) {
        delete editableData[key];

        _message.success('修改成功！');
      } else {
        _message.error(res.message);
      }
    }; //查看人员


    const peopleVisible = ref(false);
    const peopleTableData = ref([]);

    const toViewPeople = async key => {
      let res = await queryEmployee({
        jobNo: key
      });

      if (res.code === 200) {
        peopleTableData.value = [];

        if (res.data.employeeList.length) {
          res.data.employeeList.forEach(item => {
            peopleTableData.value.push({
              key: item.userNo,
              name: item.userName,
              iPhone: item.userMobile
            });
          });
        }

        peopleVisible.value = true;
      } else {
        _message.error('查看人员失败！');
      }
    }; //删除


    const onDelete = async key => {
      let res = await deletePosts({
        jobNo: key
      });

      if (res.code === 200) {
        _message.success('删除成功！');

        getTableData();
      } else {
        _message.error('删除失败！');
      }
    }; //权限配置


    const jusVisible = ref(false);
    const keyValue = ref([]);
    const jobNo = ref("");

    const tocConf = async key => {
      keyValue.value = [];
      let res = await jobQuery({
        jobNo: key
      }); // window.resObj = res.data.menuList

      try {
        if (res.code === 200 && res.data.menuList.length) {
          const treeData = getMenuTree(res.data.menuList, 2);
          treeForeach(treeData, node => {
            keyValue.value.push(node.key);
          }); // console.log(keyValue.value )
        } else {
          keyValue.value = [];
        }

        nextTick(() => {
          jusVisible.value = true;
          jobNo.value = key;
        });
      } catch (error) {
        console.log('查询岗位权限菜单', error);
      }
    };

    const updateValue = val => {
      // console.log(val)
      keyValue.value = val;
    };

    const isOk = async val => {
      const param = {
        jobNo: jobNo.value,
        menuList: val
      };
      window.paramObj = param;
      let res = await jobModify(param);

      if (res.code == 200) {
        _message.success('操作成功！');

        jusVisible.value = false;
      } else {
        _message.error('操作失败！');
      }
    }; //关闭权限配置弹窗


    const closeJusVisible = val => {
      jusVisible.value = val;
    }; //工人岗位弹窗


    const labelVisible = ref(false);

    const handleLable = () => {
      labelVisible.value = true;
    }; //查询表格数据


    const getTableData = async () => {
      loading.value = true;
      let res = await getPosts({
        jobType: 1
      });

      try {
        tableData.value = [];

        if (res.data.jobList.length) {
          res.data.jobList.forEach(item => {
            tableData.value.push({
              key: item.jobNo,
              jobName: item.jobName
            });
          });
        }

        loading.value = false;
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    const store = useStore(); //判断是否是总站

    const isMasterStation = computed(() => store.state.user.isCompanyAdmin);

    const trim = key => {
      if (key) {
        editableData[key].jobName = editableData[key].jobName.replace(/\s/g, ""); // 去除字符串全部空格
        // console.log(editableData[key].jobName)
      }
    }; //判断是否是总站及公司管理员


    const isAdmin = computed(() => {
      if (Cookies.get('isCompanyAdmin') == '1' && Cookies.get('belongProject') == '10000') {
        return true;
      } else if (Cookies.get('isCompanyAdmin') == '1' && Cookies.get('belongProject') != '10000') {
        return false;
      } else if (Cookies.get('isCompanyAdmin') == '0') {
        return true;
      }
    });
    onMounted(() => {
      getTableData();
    });
    return {
      addPost,
      // paginationConfig,
      columns,
      tableData,
      loading,
      // onPageChange,
      tocConf,
      toViewPeople,
      onDelete,
      editableData,
      edit,
      save,
      addPostVisible,
      handleOk,
      formState,
      formRef,
      handleCancel,
      peopleVisible,
      peopleColumns,
      peopleTableData,
      jusVisible,
      closeJusVisible,
      labelVisible,
      handleLable,
      keyValue,
      updateValue,
      isOk,
      isMasterStation,
      trim,
      isAdmin
    };
  }

});